.account__header__content {
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  word-break: normal;
  word-wrap: break-word;

  p {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
