.reply-mentions {
  @apply text-gray-700 dark:text-gray-600 mb-1 text-sm;

  &__account {
    @apply text-primary-600 dark:text-accent-blue hover:text-primary-700 dark:hover:text-accent-blue no-underline hover:underline;
  }
}

.status__wrapper {
  .reply-mentions {
    display: block;

    span {
      cursor: pointer;
    }
  }
}
