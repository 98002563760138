.search {
  position: relative;
}

.search__icon {
  &::-moz-focus-inner {
    border: 0;
  }

  &::-moz-focus-inner,
  &:focus {
    outline: 0 !important;
  }

  .svg-icon {
    @include font-size(16);
    cursor: default;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    z-index: 2;
    width: 18px;
    height: 18px;
    color: var(--primary-text-color--faint);
    opacity: 0;
    pointer-events: none;

    &.active {
      pointer-events: auto;
      opacity: 1;
    }
  }

  .svg-icon--search.active {
    pointer-events: none;
  }

  .svg-icon--backspace {
    cursor: pointer;
    color: var(--highlight-text-color);
    width: 22px;
    height: 22px;

    &:hover {
      color: var(--brand-color);
    }
  }
}

.column {
  .search {
    padding: 10px 15px;
    background-color: var(--foreground-color);
    border-bottom: 1px solid hsla(var(--primary-text-color_hsl), 0.2);
  }

  .search__icon .svg-icon {
    right: 24px;
  }
}
