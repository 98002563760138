.thread {
  @apply bg-white dark:bg-primary-900 p-4 shadow-xl dark:shadow-none sm:p-6 sm:rounded-xl;

  &__status {
    @apply relative pb-4;

    .status__wrapper {
      @apply shadow-none p-0;
    }
  }

  .status__content-wrapper {
    padding-left: calc(42px + 12px);
  }

  // &__descendants &__status:first-child {
  //   margin-top: 10px;

  //   .status__wrapper--filtered {
  //     margin-top: -10px;
  //   }
  // }

  // &__status--focused:first-child,
  // &__ancestors &__status:first-child {
  //   margin-top: 10px;

  //   .status__wrapper--filtered {
  //     margin-top: -10px;
  //   }
  // }

  // &__descendants &__status:last-child {
  //   margin-bottom: 10px;

  //   .status__wrapper--filtered {
  //     margin-bottom: -10px;
  //   }
  // }

  &__connector {
    @apply bg-gray-200 dark:bg-primary-800 absolute w-0.5 left-5 hidden;

    &--bottom {
      @apply block;
      height: calc(100% - 42px - 8px - 1rem);
      top: calc(12px + 42px);
    }
  }
}

.thread {
  .ptr,
  .ptr__children {
    background: var(--foreground-color) !important;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    @media screen and (max-width: 580px) {
      border-radius: 0;
    }
  }
}
